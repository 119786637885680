import { useState } from "preact/hooks";

export default function Form() {
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function submit(e: SubmitEvent) {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(e.target as HTMLFormElement);
    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.message) {
        setResponseMessage(data.message);
        setIsSuccess(response.ok);
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form onSubmit={submit} className="flex flex-col gap-3 w-full max-w-sm">
      <input
        type="email"
        name="email"
        required
        placeholder="your@email.com"
        className="rounded-[16px] text-base font-medium px-6 h-[48px] bg-white/10 border border-white/15 w-full placeholder:text-gray-500 focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-blue-500 focus:outline-none"
        disabled={isSubmitting}
      />

      <div className="p-1 border-white/15 rounded-[20px] border bg-white/10">
        <button
          className={`flex w-full justify-center items-center px-6 h-[48px] font-medium rounded-[16px] text-white bg-gradient-to-t from-blue-700 to-blue-500 border-t border-blue-300 hover:from-indigo-700 hover:to-blue-500 transition-colors ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Join the waitlist"}
          <svg
            className="text-white ml-2"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M152,224a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,224ZM128,112a12,12,0,1,0-12-12A12,12,0,0,0,128,112Zm95.62,43.83-12.36,55.63a16,16,0,0,1-25.51,9.11L158.51,200h-61L70.25,220.57a16,16,0,0,1-25.51-9.11L32.38,155.83a16.09,16.09,0,0,1,3.32-13.71l28.56-34.26a123.07,123.07,0,0,1,8.57-36.67c12.9-32.34,36-52.63,45.37-59.85a16,16,0,0,1,19.6,0c9.34,7.22,32.47,27.51,45.37,59.85a123.07,123.07,0,0,1,8.57,36.67l28.56,34.26A16.09,16.09,0,0,1,223.62,155.83ZM99.43,184h57.14c21.12-37.54,25.07-73.48,11.74-106.88C156.55,47.64,134.49,29,128,24c-6.51,5-28.57,23.64-40.33,53.12C74.36,110.52,78.31,146.46,99.43,184Zm-15,5.85Q68.28,160.5,64.83,132.16L48,152.36,60.36,208l.18-.13ZM208,152.36l-16.83-20.2q-3.42,28.28-19.56,57.69l23.85,18,.18.13Z"></path>
          </svg>
        </button>
      </div>
      {responseMessage && (
        <div
          className={`py-2 rounded-[16px] w-fit mx-auto px-5 text-xs ${
            isSuccess
              ? "bg-green-100/5 text-green-400 border border-green-700"
              : "bg-red-100/5 text-red-400 border border-red-700"
          }`}
        >
          <p className="font-medium text-sm">
            {isSuccess ? "Success" : "Error"}
          </p>
          <p>{responseMessage}</p>
        </div>
      )}
    </form>
  );
}
